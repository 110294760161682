<template>
  <div class="logo">
    <figure>
      <img alt="Spice Krewe official launch at this year's boil" src="../assets/flavor-spice-krewe-min.png"/>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'SpiceKreweAwards',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
