<template>
  <section class="content-section">
    <h2>April 20th, 12pm - 6pm</h2>
    <h2>What to Expect</h2>
    <ul>
      <li>Good eatin' Crawfish, Potatoes, Corn, Sausage and more!</li>
      <li>A large side yard with plenty of room for you to bring the whole family and your favorite yard games. Bring your own chairs.</li>
      <li>Music, games and good friends</li>
      <li>Spice Krewe flavors and merch available!</li>
    </ul>
    <h2>New This Year!</h2>
    <ul>
      <li>Spice Krewe MC and events!</li>
      <li>Pardoning of the Crawfish</li>
      <li>Celebration of the boil team and paddle presentation</li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'WhatToExpect',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-section{
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
</style>
