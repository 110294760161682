<template>
  <section>
    <div class="logo">
      <figure>
        <img alt="Spice Krewe official launch at this year's boil" src="../assets/flavor-min.png"/>
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SpiceKreweLaunchDay',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
section{
 margin-top: -7px;
}
</style>
